<template>
  <div class="modal fade"
       :id="id"
       tabindex="-1"
       role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-campaign-detailsLabel">Campaign Attributes</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="loading"
               class="d-flex items-center justify-content-center w-100 py-5">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            {{ loadingMessage }}
          </div>
          <div class="statbox widget box p-0" v-else-if="!loading && campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <td>{{ campaign.id }}</td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td>{{ campaign.name }}</td>
                    </tr>
                    <tr>
                      <th>Budget Optimization</th>
                      <td>{{ campaign.budget_optimization }}</td>
                    </tr>
                    <tr>
                      <th>Bid Strategy</th>
                      <td>{{ campaign.line_items[0]?.bid_strategy }}</td>
                    </tr>
                    <tr>
                      <th>Objective</th>
                      <td>{{ campaign.line_items[0]?.objective }}</td>
                    </tr>
                    <tr>
                      <th>Daily Budget</th>
                      <td>{{ campaign.daily_budget_amount_local_micro ? (campaign.currency + ' ' + campaign.daily_budget_amount_local_micro / 1000000) : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Total Budget</th>
                      <td>{{ campaign.total_budget_amount_local_micro ? (campaign.currency + ' ' + campaign.total_budget_amount_local_micro / 1000000) : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <th>Start Time</th>
                      <td>{{ formatDate(campaign.line_items[0]?.start_time) }}</td>
                    </tr>
                    <tr>
                      <th>End Time</th>
                      <td>{{ formatDate(campaign.line_items[0]?.end_time) }}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{{ campaign.entity_status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['id'],
  data() {
    return {
      loadingMessage: '',
      loading: false,
      campaign: null
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById(this.id));
  },
  methods: {
    show({twitter_account_id, twitter_campaign_id}) {
      this.loadCampaign(twitter_account_id, twitter_campaign_id);
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    formatDate(date) {
      return date ? window.moment(date).format('MMMM Do YYYY, h:mm:ss a') : 'N/A';
    },
    loadCampaign(twitter_account_id, twitter_campaign_id) {
      this.campaign = null;
      this.loading = true;
      this.loadingMessage = "Reaching out to X...";

      this.axios.get(`/twitter/campaigns/${twitter_account_id}/${twitter_campaign_id}`)
        .then(response => {
          this.campaign = response.data.data;
          this.loading = false;
        })
        .catch(() => {});
    }
  }
}
</script>